
import firebase from 'firebase/compat/app'
import Mixins from '@/shared.js'
import CreateMatchComponent from '@/components/CreateMatch.component.vue'
import ImportComponent from '@/components/ImportMatches.component.vue'
import GroupTeamManagement from '@/components/GroupTeamManagement.component.vue'
import MatchItem from '@/views/competitions/matchItem.vue'
import MatchReport from '@/components/MatchReport.component.vue'
import GroupStandings from '@/components/GroupStandings.component.vue'
import ValidateGroupComponent from '@/components/Group/ValidateGroupComponent.vue'

// Dashboard for group
export default {
  name: 'GroupPage',
  components: {
    GroupTeamManagement,
    CreateMatchComponent,
    ImportComponent,
    MatchReport,
    MatchItem,
    GroupStandings,
    ValidateGroupComponent
  },
  mixins: [Mixins],
  beforeRouteUpdate (to: any, from: any, next: any) {
    this.$store
      .dispatch('MatchModule/openDBChannel', {
        clauses: { where: [['groupId', '==', to.params.groupId]], limit: 0 }
      })
      .then(() => {
        this.items = this.$store.getters['MatchModule/filterByGroup'](
          to.params.groupId
        )
      })
    this.competitionData = to.params.competition
    this.groupData = this.$store.getters['GroupModule/filterTeamsByGroup'](
      to.params.groupId
    )[0]
    this.componentKey = to.params.groupId.toLowerCase()
    next()
  },
  data: () => ({
    singleSelect: false,
    selected: [],
    loading: 0,
    dialogImport: false,
    search: '',
    items: [],
    competitionData: {
      name: ''
    },
    groupData: {
      name: '',
      gender: '',
      cup: false
    },
    componentKey: '',
    canUpload: null,
    canValidate: null
  }),
  computed: {
    title () {
      return this.competitionData.name + ' : ' + this.groupData.name
    },
    headers () {
      return [
        {
          text: this.$t('ID'),
          align: 'start',
          sortable: true,
          value: 'matchId'
        },
        {
          text: this.$t('date'),
          sortable: true,
          value: 'date',
          cellClass: 'nowrap'
        },
        {
          text: this.$t('round'),
          sortable: false,
          value: 'round'
        },
        { text: this.$t('home'), value: 'homeTeam.name', cellClass: 'nowrap' },
        { text: this.$t('away'), value: 'awayTeam.name', cellClass: 'nowrap' },
        { text: this.$t('venue'), sortable: false, value: 'venue.name', cellClass: 'nowrap' },
        { text: this.$t('livescore_short'), value: 'livescore', sortable: false, cellClass: 'nowrap' },
        { value: 'actions', sortable: false }
      ]
    },
    cup () {
      return this.groupData.cup
    }
  },
  mounted () {
    this.loading = 2
    this.$store.dispatch('MatchModule/fetchAndAdd', {
      clauses: {
        where: [['groupId', '==', this.$route.params.groupId]],
        limit: 0
      }
    })
    this.$store
      .dispatch('CompetitonsModule/fetchAndAdd', {
        where: [['id', '==', this.$route.params.competitionId]]
      })
      .then(() => {
        this.competitionData =
          this.$store.state.CompetitonsModule.data[
            this.$route.params.competitionId
          ]
        this.loading = 0
      })
    this.$store
      .dispatch('GroupModule/fetchAndAdd', {
        where: [['id', '==', this.$route.params.groupId]]
      })
      .then(() => {
        this.groupData =
          this.$store.state.GroupModule.data[this.$route.params.groupId]
      })
    this.canUpload = JSON.parse(
      firebase.remoteConfig().getValue('modules').asString()
    )[0].import_matches
    this.canValidate = JSON.parse(
      firebase.remoteConfig().getValue('modules').asString()
    )[0].validate_matches
  },
  methods: {
    unlinkMatch () {
      const ids = this.selected.map((s: { id: any }) => s.id)
      this.$store.dispatch('MatchModule/deleteBatch', ids).then(() => {
        this.selected = []
      })
    }
  }
}
