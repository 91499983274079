
import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import axios from 'axios'
import Mixins from '@/shared'
import { showSnackbar } from '@/globalActions'
export default {
  name: 'StandingsTable',
  mixins: [Mixins],
  props: {
    groupId: {
      type: String,
      required: true,
      default: 'null'
    }
  },
  data: () => {
    return {
      loading: false,
      standingsTable: []
    }
  },
  created () {
    this.showStandingsTable()
  },
  methods: {
    generateStandingSnapshot () {
      this.loading = true
      const reportCall = firebase
        .functions()
        .httpsCallable('generateStandingSnapshot')
      reportCall({ groupId: this.groupId })
        .then(() => {
          showSnackbar('Standings table generation submitted.')
          this.loading = !this.loading
        }).catch(() => {
          this.loading = false
        })
    },
    async fillEmptyTable () {
      this.standingsTable = []
      if (this.groupId !== undefined) {
        await this.$store.dispatch('GroupModule/fetchById', this.groupId).then((data: { teams: any[] }) => {
          if ('teams' in data) {
            data.teams.forEach(team => {
              this.standingsTable.push(team)
            })
            this.loading = false
            return
          }
          this.loading = !this.loading
        })
      } else {
        this.loading = false
      }
    },
    showStandingsTable () {
      this.loading = true
      if (this.groupId !== 'null') {
        axios.get(process.env.VUE_APP_API_DOMAIN_PATH + '/webApi/groups/' + this.groupId + '/standings')
          .then(response => {
            try {
              this.standingsTable = []
              if (response.status === 200) {
                this.standingsTable = response.data.table
                this.loading = false
                return
              }
              this.fillEmptyTable()
            } catch (error) {
              this.fillEmptyTable()
            }
          }).catch(() => {
            this.fillEmptyTable()
          })
      }
    }
  }
}
