import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"1"}},[_c(VCardTitle,{staticClass:"pt-6 pb-6"},[_vm._v(" "+_vm._s(_vm.$t('standings-table'))+" "),_c(VSpacer),_c(VBtn,{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"color":"primary","data-index":"1","icon":""},on:{"click":function($event){return _vm.generateStandingSnapshot(_vm.groupId)}}},[_c(VIcon,[_vm._v("mdi-cached")])],1)],1),_c(VCardText,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('team'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('matches'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('goals'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('goals-against'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('goals-difference'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('points'))+" ")])])]),_c('tbody',_vm._l((_vm.standingsTable),function(team,i){return _c('tr',{key:i + 'standings'},[_c('td',{staticClass:"d-flex align-center"},[_c(VImg,{staticStyle:{"margin-right":"15px"},attrs:{"src":_vm.getClubLogo(team.clubId),"max-height":"32","max-width":"32"}}),_vm._v(" "+_vm._s(team.name)+" ")],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(Number.isInteger(team.games) ? team.games : 0)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(Number.isInteger(team.goals) ? team.goals : 0)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(Number.isInteger(team.against) ? team.against : 0)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(Number.isInteger(team.goals) ? team.goals - team.against : 0)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(Number.isInteger(team.points) ? team.points : 0)+" ")])])}),0)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }