
import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import 'firebase/compat/auth'
import { showSnackbar } from '../globalActions'
import Mixins from '@/shared'

import { MatchResult } from '@/interfaces/matchResult'
import { MatchEvent } from '@/interfaces/matchEvent'

export default {
  name: 'MatchReport',
  mixins: [Mixins],
  props: {
    match: {
      type: Object,
      default: () => ({ empty: true })
    }
  },
  data () {
    return {
      layout: {
        fluid: true
      },
      matchResult: {},
      players: [],
      teams: [] as Array<unknown>,
      score: [0, 0],
      loading: false,
      saving: false,
      loadingStandings: false,
      downloadString: null,
      matchReportDialog: false,
      liveEventLog: []
    }
  },
  computed: {
    homeTeamShortName (): string {
      if ('shortName' in this.match.homeTeam) {
        return this.match.homeTeam.shortName
      }
      return this.match.homeTeam.name.substring(0, 4)
    },
    awayTeamShortName (): string {
      if ('shortName' in this.match.awayTeam) {
        return this.match.awayTeam.shortName
      }
      return this.match.awayTeam.name.substring(0, 4)
    },
    homeTeamScore (): number {
      if (
        'home' in this.match.matchResult &&
        Object.keys(this.match.matchResult.home).length > 0
      ) {
        return this.match.matchResult.home.goalsFor
      }
      return 0
    },
    awayTeamScore (): number {
      if (
        'away' in this.match.matchResult &&
        Object.keys(this.match.matchResult.away).length > 0
      ) {
        return this.match.matchResult.away.goalsFor
      }
      return 0
    },
    homeTeamPlayers (): Array<unknown> {
      if ('players' in this.match.homeTeam) {
        return [...this.match.homeTeam.players].sort(
          (a: any, b: any) => a.number - b.number
        )
      }
      return []
    },
    awayTeamPlayers (): Array<unknown> {
      if ('players' in this.match.awayTeam) {
        return [...this.match.awayTeam.players].sort(
          (a: any, b: any) => a.number - b.number
        )
      }
      return []
    },
    homeTeamBench (): Array<unknown> {
      return this.getBench(this.match.homeTeam)
    },
    awayTeamBench (): Array<unknown> {
      return this.getBench(this.match.awayTeam)
    },
    eventLog (): Array<MatchEvent> {
      return this.filterEvents()
    },
    homeScore () {
      return this.score[0]
    },
    awayScore () {
      return this.score[1]
    }
  },
  methods: {
    openDialog () {
      this.matchReportDialog = !this.matchReportDialog
      const matchResultSetup: MatchResult = {
        home: {
          goalsFor: 0,
          goalsAgainst: 0,
          points: 0
        },
        away: {
          goalsFor: 0,
          goalsAgainst: 0,
          points: 0
        },
        halfTime: {
          home: 0,
          away: 0
        },
        normalTime: {
          home: 0,
          away: 0
        },
        fullTime: {
          home: 0,
          away: 0
        }
      }
      if (
        !('matchResult' in this.match) ||
        !('home' in this.match.matchResult) ||
        Object.keys(this.match.matchResult).length === 0
      ) {
        this.matchResult = matchResultSetup
      } else {
        this.matchResult = {
          ...matchResultSetup,
          ...this.match.matchResult
        }
      }
      if ('players' in this.match.homeTeam) {
        const awayPlayers = ('players' in this.match.awayTeam) ? this.match.awayTeam.players : []
        this.players = this.match.homeTeam.players.concat(
          awayPlayers
        )
      } else {
        this.players = []
      }
      this.teams.push(this.match.homeTeam)
      this.teams.push(this.match.awayTeam)
      this.$store.dispatch('GroupModule/fetchById', this.match.groupId).then(() => {
        const id = this.$store.state.GroupModule.data[this.match.groupId].competitionId
        this.$store.dispatch('CompetitonsModule/fetchById', id)
        const teams = [
          ['id', 'in', [this.match.homeTeam.teamId, this.match.awayTeam.teamId]]
        ]
        this.$store.dispatch('ClubTeamsModule/fetchAndAdd', { where: teams })
      })
      if (this.match.state === 'ON_GOING') {
        this.$store.dispatch('MatchModule/openDBChannel', { where: [['id', '==', this.match.id]] })
          .then(({ streaming, stop }) => {
            streaming.then(() => {
              stop()
            }).catch((error: any) => console.error(error))
          })
          .catch((error: any) => {
            console.error('catch', error)
          })
      }
    },
    closeDialog () {
      this.$store.dispatch('MatchModule/closeDBChannel')
      this.loading = false
      this.matchReportDialog = !this.matchReportDialog
    },
    generateReport (): void {
      this.loading = true
      const id = this.match.id
      const competitionId = this.$store.state.GroupModule.data[this.match.groupId].competitionId
      const promise = new Promise((resolve) => {
        this.$store.dispatch('ClubsModule/fetchAndAdd').then(() => {
          this.$store
            .dispatch('MatchModule/patch', {
              id,
              competitionId: competitionId,
              competitionName: this.$store.state.CompetitonsModule.data[competitionId].name,
              homeTeam: {
                clubId: this.$store.state.ClubTeamsModule.data[this.match.homeTeam.teamId].clubId,
                shortName:
                  this.$store.state.ClubsModule.data[
                    this.$store.state.ClubTeamsModule.data[this.match.homeTeam.teamId].clubId
                  ].shortName,
                bench: this.getBench(this.match.homeTeam)
              },
              awayTeam: {
                clubId: this.$store.state.ClubTeamsModule.data[this.match.awayTeam.teamId].clubId,
                shortName:
                  this.$store.state.ClubsModule.data[
                    this.$store.state.ClubTeamsModule.data[this.match.awayTeam.teamId].clubId
                  ].shortName,
                bench: this.getBench(this.match.awayTeam)
              }
            })
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              showSnackbar(error, { duration: 5000, color: 'danger' })
              this.loading = false
            })
        })
      })
      promise.then(async (status) => {
        if (status) {
          const reportCall = firebase
            .functions()
            .httpsCallable('generateMatchReport')
          await reportCall({ match: this.match.id })
            .then((result) => {
              this.loading = false
              window.open(result.data)
            })
            .catch((error) => {
              showSnackbar(error, { duration: 5000, color: 'danger' })
              this.loading = false
            })
        } else {
          this.loading = false
        }
      }).catch((error) => {
        showSnackbar(error, { duration: 5000, color: 'danger' })
        this.loading = false
      })
    },
    openReport (): void {
      window.open(`${this.downloadString}`)
    },
    filterEvents (): Array<MatchEvent> {
      if (!('eventLog' in this.match) && !('matchLog' in this.match)) return []
      /* eslint-disable no-prototype-builtins */
      const log = ('eventLog' in this.match) ? this.match.eventLog : Object.entries(this.match.matchLog).map(([key, value]) => ({ ...value as Record<string, unknown> }))
      const events = log.filter(
        (e: { hidden: boolean; edited: boolean; deleted: boolean }) =>
          e.hidden !== true && e.edited !== true && e.deleted !== true
      )
      return events.sort((a: MatchEvent, b: MatchEvent) => {
        if (a.matchTime === b.matchTime) {
          // If two logEvents have the same matchTime, then the one who has lowest order wins
          return a.order - b.order
        } else {
          // If two logEvents have different matchTime, then the one who has lowest matchTime wins
          return a.matchTime - b.matchTime
        }
      })
    },
    getPlayerName (who: { playerId: string; eventType: string }) {
      if (!('playerId' in who)) return
      try {
        return this.players[
          this.players.findIndex((p: { id: string }) => p.id === who.playerId)
        ].name
      } catch (e) {
        return '?'
      }
    },
    getTeam (data: MatchEvent, side: number) {
      if (!('teamId' in data)) return
      const team = this.teams.findIndex(
        (p: { teamId: string }) => p.teamId === data.teamId
      )
      return team === side
    },
    getTeamName (teamId: string) {
      if (teamId === undefined) return
      return this.teams[
        this.teams.findIndex((p: { teamId: string }) => p.teamId === teamId)
      ].name
    },
    getBench (team: any) {
      if ('bench' in team) {
        if (typeof team.bench === 'object') {
          const bench = Object.entries(team.bench as unknown).map(key => ({ ...key[1] }))
          return bench.sort((a, b) => a.letter.localeCompare(b.letter))
        }
        return [...team.bench].sort((a, b) => a.letter.localeCompare(b.letter))
      }
      return [{ letter: 'A', name: '' }, { letter: 'B', name: '' }, { letter: 'C', name: '' }, { letter: 'D', name: '' }]
    },
    isGoal (eventType: string) {
      const types = ['goal', 'goal7meters']
      return types.includes(eventType)
    },
    calculateScore (event: MatchEvent) {
      const team = this.teams.findIndex(
        (p: { teamId: string }) => p.teamId === event.teamId
      )
      if (this.isGoal(event.eventType)) {
        ++this.score[team]
        return `${this.score[0]} - ${this.score[1]}`
      }
    },
    alignText (event: MatchEvent, side: number, i: number) {
      if (!('teamId' in event) || i === 0) return 0
      const team = this.teams.findIndex(
        (p: { teamId: string }) => p.teamId === event.teamId
      )
      return (team === side && i < this.eventLog.length)
    },
    nearestMinute (time: number) {
      if (time === undefined || time < 60) return 1
      return Math.ceil((time * 1000) / 60000)
    },
    saveMatchResult () {
      this.saving = !this.saving
      const id = this.match.id
      this.matchResult.home.goalsAgainst = this.matchResult.away.goalsFor
      this.matchResult.away.goalsAgainst = this.matchResult.home.goalsFor
      this.matchResult.fullTime = {
        home: this.matchResult.home.goalsFor,
        away: this.matchResult.away.goalsFor
      }
      this.matchResult.normalTime = {
        home: this.matchResult.home.goalsFor,
        away: this.matchResult.away.goalsFor
      }

      if (parseInt(this.matchResult.home.goalsFor) > parseInt(this.matchResult.away.goalsFor)) {
        this.matchResult.home.points = 2
        this.matchResult.away.points = 0
      } else if (parseInt(this.matchResult.home.goalsFor) < parseInt(this.matchResult.away.goalsFor)) {
        this.matchResult.home.points = 0
        this.matchResult.away.points = 2
      } else if (parseInt(this.matchResult.home.goalsFor) === parseInt(this.matchResult.away.goalsFor)) {
        this.matchResult.home.points = 1
        this.matchResult.away.points = 1
      } else {
        this.matchResult.home.points = 0
        this.matchResult.away.points = 0
      }

      this.$store
        .dispatch('MatchModule/patch', {
          id,
          matchResult: this.matchResult
        })
        .then(() => {
          showSnackbar('Match result saved.', { color: 'success' })
          this.saving = !this.saving
        })
    },
    isIterable (value: any) {
      return Object.keys(Object(value)).length > 0
    }
  }
}
