
import Mixins from '@/shared'
import firebase from 'firebase/compat/app'
import { Match } from '@/interfaces/match'
import GroupTeamManagement from '@/components/GroupTeamManagement.component.vue'

export interface LocationItem {
  name: string;
  venueId: string;
}
export interface TeamItem {
  name: string;
  orgName: string;
  teamId: string;
  clubId: string;
  checked: boolean;
}

export interface Group {
  teams: Array<string>;
  gender: string;
}

export interface Officials {
  name: string;
  role: string;
}
// Component for creating a match in admin interface
export default {
  name: 'CreateMatch',
  components: {
    GroupTeamManagement
  },
  mixins: [Mixins],
  beforeRouteUpdate (to: unknown, from: unknown, next) {
    next()
  },
  props: {
    groupId: {
      type: String,
      default: '',
      required: true
    },
    competitionName: {
      type: String,
      default: '',
      required: true
    },
    group: {
      type: Object,
      default: function (): Group {
        return { teams: [], gender: 'male' }
      },
      required: true
    },
    ageGroup: {
      type: Object,
      default: function () {
        return { name: 'Vaksin' }
      },
      required: true
    }
  },
  data: () => {
    return {
      modal: false,
      display: false,
      dateMenu: false,
      states: ['ANNOUNCED'],
      locations: Array<LocationItem>(),
      inputDate: new Date().toISOString().substr(0, 10),
      createData: {
        groupId: '',
        competitionName: '',
        matchId: '',
        time: '',
        date: {},
        homeTeam: {
          name: '',
          teamId: '',
          clubId: '',
          shortName: ''
        },
        awayTeam: {
          name: '',
          teamId: '',
          clubId: '',
          shortName: ''
        },
        group: {},
        round: '',
        matchLength: '2x30m|2x5m|p',
        officials: [{
          name: '',
          role: 'referee1'
        }, {
          name: '',
          role: 'referee2'
        }, {
          name: '',
          role: 'secretary'
        }, {
          name: '',
          role: 'timeKeeper'
        }, {
          name: '',
          role: 'delegate'
        }],
        venue: {},
        state: 'ANNOUNCED'
      } as unknown as Match,
      teams: Array<TeamItem>(),
      items: [],
      listener: null,
      model: Array<TeamItem>()
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.inputDate)
    }
  },
  methods: {
    // @vuese
    // Saves match to database
    createMatch () {
      this.createData.groupId = this.groupId
      this.createData.competitionName = this.competitionName
      this.createData.group = {
        ...this.filterObj(this.group, ['competitionName', 'name', 'gender', 'age', 'cup']),
        competitionName: this.competitionName,
        age: this.ageGroup
      }
      this.createData.homeTeam = this.filterObj(this.createData.homeTeam, ['name', 'teamId', 'clubId'])
      this.createData.awayTeam = this.filterObj(this.createData.awayTeam, ['name', 'teamId', 'clubId'])
      const matchTime = firebase.firestore.FieldValue.serverTimestamp()
      this.createData.date = firebase.firestore.Timestamp.fromDate(new Date(this.inputDate + ' ' + this.createData.time))
      this.$store.dispatch('MatchModule/insert', this.createData).then(() => {
        Object.keys(this.createData).forEach((key) => {
          if (['officials', 'matchId', 'time', 'round'].includes(key)) return
          this.createData[key] = ''
        })
      }).catch((error: string) => {
        console.error('Error', error)
      })
    }
  }
}
