import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBtn,{staticClass:"ml-2",attrs:{"color":"info","outlined":"","rounded":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_c(VIcon,[_vm._v(" mdi-heart ")])],1),_c(VDialog,{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Validera dystir. "),_c(VSpacer),_c(VIcon,{attrs:{"color":"info"}},[_vm._v(" mdi-heart ")])],1),_c(VCardText,[_c('p',[_vm._v("Við hesum er møguligt at validera allar dystirnar í bólkinum.")]),_c('p',[_vm._v("Nýt hesa funku um onkur broyting er gjørd fyri kappingina ella bólkin, soleiðis at allir dystirnir fáa røttu upplýsingar.")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"default darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Lat aftur ")]),_c(VBtn,{attrs:{"color":"info darken-1","text":"","loading":_vm.loading},on:{"click":_vm.validateGroupMatches}},[_vm._v(" Validera ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }