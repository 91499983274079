
import Vue from 'vue'
import firebase from 'firebase/compat/app'
import { Match } from '@/interfaces/match'
import Mixins from '../shared'
export default Vue.extend({
  name: 'MatchImport',
  mixins: [Mixins],
  props: {
    group: {
      type: Object,
      required: true,
      default: function () {
        return {
          id: String, name: String, gender: String, teams: Object
        }
      }
    },
    competition: {
      type: Object,
      required: true,
      default: function () {
        return { id: String, name: String, ageGroup: String, typeId: Number }
      }
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      errorDialog: false,
      errorMessage: '',
      file: '',
      replaceMatchingIds: true,
      currentMatchIds: [],
      importMatchIds: [],
      skipped: 0
    }
  },
  methods: {
    handleFileUpload (file: any) {
      this.file = file
    },
    submitFile () {
      // const storageRef = firebase.storage().ref(this.file.name).put(this.file)
      // storageRef.on('state_changed', snapshot => {
      //   this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      // }, error => {
      //   console.log(error.message)
      // }, () => {
      //   this.uploadValue = 100
      //   storageRef.snapshot.ref.getDownloadURL().then((url) => {
      //     this.$data.dialog = true
      //   })
      // })
      // this.$data.dialog = false
      this.parseImport()
    },
    findTeam (team: string) {
      if (!('teams' in this.group)) return this.$t('MISSING')
      const data = this.group.teams.find((e: { name: string }) => e.name === team)
      if (data === undefined) return this.$t('MISSING')
      return {
        name: data.name,
        teamId: data.teamId,
        clubId: data.clubId,
        shortName: data.name.substring(0, 4)
      }
    },
    findVenue (venue: string) {
      const locationData = Object.values(this.$store.state.venues.data).find((e: {name: string}) => e.name === venue)
      if (locationData !== undefined) return locationData
      return this.$t('MISSING')
    },
    removeDuplicates (lines: any) {
      const newLines = []
      this.currentMatchIds = this.$store.getters['MatchModule/getMatchIds'](this.group.id)
      for (let index = 1; index < lines.length; index++) {
        const elements = lines[index].split(';')
        if (this.currentMatchIds.includes(elements[0])) {
          this.skipped++
          continue
        }
        newLines.push(elements)
      }
      return newLines
    },
    async parseImport () {
      const data = []
      const fr = new FileReader()
      fr.readAsText(this.file)
      fr.onloadstart = () => {
        this.loading = true
      }
      fr.onload = e => {
        // eslint-disable-next-line
        const lines = (e.target.result as string).split(/(?:\r\n|\n)+/).filter(function (el) { return el.length !== 0 })
        const linesToImport = this.removeDuplicates(lines)

        if (linesToImport.length === 0) {
          this.errorMessage = this.$t('import-no-lines-found') // 'No new lines found to import.'
          this.errorDialog = true
          this.loading = false
          return
        }

        for (let index = 0; index < linesToImport.length; index++) {
          const elements = linesToImport[index]
          let datetime = null
          let date = null
          try {
            if (elements[2].includes('-')) {
              date = elements[2].split('-')
            } else {
              date = elements[2].split('/')
            }
            if (date.length !== 3) {
              this.errorMessage = `${this.$t('import-no-lines-found')} ${elements[0]}` // Date is not correct for id
              this.errorDialog = true
              this.loading = false
              return 0
            }
            datetime = new Date(date[2], parseInt(date[1]) - 1, date[0])
          } catch (err) {
            this.errorMessage = `${this.$t('import-error-with-line')}: ${elements[0]}. ${this.$t('import-possible-reason')}: ${err}` // There is something wrong with
            this.errorDialog = true
            this.loading = false
            return 0
          }
          const element: Match = {
            date: null,
            time: null,
            groupId: this.group.id,
            matchId: elements[0],
            round: parseInt(elements[4]),
            homeTeam: this.findTeam(elements[5]),
            awayTeam: this.findTeam(elements[6]),
            competitionName: this.competition.name,
            venue: this.findVenue(elements[8]),
            matchLength: elements[9] ? elements[9] : null,
            comment: (elements[10] !== undefined) ? elements[10] : null,
            note: null,
            state: 'ANNOUNCED',
            group: {
              competitionName: this.competition.name,
              name: this.group.name,
              gender: this.group.gender,
              age: this.competition.ageGroup,
              cup: this.group.cup
            }
          }
          const time = elements[3].split(/[:.]+/)
          if (datetime !== null && Number.isInteger(parseInt(time[0]))) {
            try {
              datetime.setHours(time[0], time[1])
              element.date = firebase.firestore.Timestamp.fromDate(new Date(datetime))
              element.time = time[0] + ':' + time[1]
            } catch (err) {
              this.errorMessage = `${this.$t('import-error-with-line')}: ${elements[0]}. ${this.$t('import-possible-reason')}: ${err}` // There is something wrong with
              this.errorDialog = true
              this.loading = false
              return 0
            }
          } else {
            element.date = datetime
            element.note = this.$t('import-notice-missing-time')
            element.state = 'INCOMPLETE_IMPORT'
          }
          data.push(element)
        }
        if (fr.readyState === 2) {
          try {
            this.$store.dispatch('MatchModule/insertBatch', data).then(() => {
              this.loading = false
              this.file = ''
              this.$emit('importSuccess')
            }).catch((error: string) => {
              this.errorMessage = `${this.$t('import-notice-missing-time')}. ${this.$t('import-possible-reason')}: ${error}`
              this.errorDialog = true
              this.loading = false
            })
          } catch (error) {
            console.error('try, error')
          }
        }
      }
    }
  }
})
