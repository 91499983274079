import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-block"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('competition'))+":")]),_vm._v(" "+_vm._s(_vm.competition.name)+" ")]),_c('div',{staticClass:"d-block"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('group'))+":")]),_vm._v(" "+_vm._s(_vm.group.name)+" ")]),_c(VFileInput,{ref:"file",attrs:{"chips":"","show-size":"","truncate-length":"24","label":"File input"},on:{"change":_vm.handleFileUpload}}),_c(VBtn,{attrs:{"text":"","block":"","color":"success","loading":_vm.loading},on:{"click":_vm.submitFile}},[_vm._v(" "+_vm._s(_vm.$t('submit-upload'))),_c(VIcon,{attrs:{"dark":"","right":""}},[_vm._v(" mdi-cloud-upload ")])],1),_c(VDialog,{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('import-file-ready'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('import-file-uploaded'))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('diagree'))+" ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.parseImport}},[_vm._v(" "+_vm._s(_vm.$t('parse-import'))+" ")])],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('import-error'))+" ")]),_c(VCardText,[_c('p',{staticClass:"red--text lighten-5"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.errorDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }