
/* eslint-disable */
import Mixins from '@/shared'
import firebase from 'firebase/compat/app'
import { Match } from '@/interfaces/match'
import { Group } from '@/interfaces/group'
import { Competition } from '@/interfaces/competition'
import GroupTeamManagement from '@/components/GroupTeamManagement.component.vue'
import { showSnackbar } from '@/globalActions'

// Edit match details
export default {
  name: 'EditMatch',
  mixins: [Mixins],
  components: {
    GroupTeamManagement
  },
  props: {
    // Match details
    matchData: {
      type: Object,
      default: () => ({ empty: true }),
      required: true
    },
    competition: {
      type: Object as () => Competition,
      required: true
    }
  },
  data: () => ({
    visible: false,
    snackbar: {
      visible: false,
      text: '' as any
    },
    match: {} as Match,
    group: {} as Group,
    dateMenu: false,
    inputDate: null as any,
    date: null as any,
    officials: [{
      name: '',
      role: 'referee1'
    }, {
      name: '',
      role: 'referee2'
    }, {
      name: '',
      role: 'secretary'
    }, {
      name: '',
      role: 'timeKeeper'
    }, {
      name: '',
      role: 'delegate'
    }]
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    matchStates () {
      const match_states = firebase.remoteConfig().getString('match_states')
      const states = match_states.split(',')
      return states
    }
  },
  created () {
    this.visible = true
    if (!this.matchData.empty) {
      this.match = this.matchData
      this.setDate()
    }
    this.group = this.$store.getters['GroupModule/filterTeamsByGroup'](this.match.groupId)[0]
  },
  mounted () {
    document.addEventListener("keydown", (e) => {
        if (e.code === 'Escape') {
          this.$emit('close');
        }
    });
  },
  methods: {
    setDate () {
      if (!this.match.date) {
        return new Date()
      }
      const t = new Date(1970, 0, 1)
      t.setSeconds((this.match.date as any).seconds)
      this.date = t.toISOString().substr(0, 10)
      this.inputDate = this.formatDate(t.toISOString().substr(0, 10))
    },
    setState (state: string) {
      const missing = Object.values(this.match).find(e => e === 'MISSING')
      if (missing) return 'error'
      return (state === 'INCOMPLETE_IMPORT') ? 'error' : 'success'
    },
    async save () {
      if (!('officials' in this.match)) {
        this.match.officials = this.officials
      }
      if(!('competitionName' in this.match)) {
        this.match.competitionName = this.competition.name
      }
      this.match.date = firebase.firestore.Timestamp.fromDate(new Date(this.date + ' ' + this.match.time))
      this.match.homeTeam = this.filterObj(this.match.homeTeam, ['name', 'shortName', 'teamId', 'clubId', 'players', 'bench'])
      this.match.awayTeam = this.filterObj(this.match.awayTeam, ['name', 'shortName', 'teamId', 'clubId', 'players', 'bench'])

      this.$store.dispatch('MatchModule/patch', this.match).then(() => {
        this.snackbar.visible = true
        this.snackbar.text = this.$t('match-updated')
        this.close()
      }).catch((error: any) => {
        showSnackbar(error, { duration: 5000 })
      })
    },
    close () {
      this.$emit('close')
    }
  }
}
