
import Shared from '../../shared.js'
import { Competition } from '@/interfaces/competition'
import EditMatch from '@/components/EditMatch.component.vue'

export default {
  name: 'MatchItem',
  components: {
    EditMatch
  },
  mixins: [Shared],
  props: {
    item: {
      type: Object,
      default: () => ({ empty: true })
    },
    competition: {
      type: Object as () => Competition,
      required: true
    }
  },
  data: () => ({
    editing: false
  })
}
